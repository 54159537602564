<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-calendar-check-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <!-- :error-messages="nomeErrors" 
                              required
                  @input="$v.popLocal.nome_pop.$touch()"
                  @blur="$v.popLocal.nome_pop.$touch()"-->

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="popLocal.pop_titulo"
                    label="Titulo"
                    outlined
                    :readonly="!mixPermissao($options.name, 'edicao')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    :value="obterUsuario(popLocal.cria_usuario)"
                    label="Criador"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="popLocal.cria_datahora"
                    label="Criação"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <!-- <v-textarea
                    onkeydown="if(event.keyCode===9){var v=this.value,s=this.selectionStart,e=this.selectionEnd;this.value=v.substring(0, s)+'\t'+v.substring(e);this.selectionStart=this.selectionEnd=s+1;return false;}"
                    hide-details="auto"
                    v-model="popLocal.pop_texto"
                    label="Pop"
                    rows="10"
                    outlined
                    :readonly="!mixPermissao($options.name, 'edicao')"
                  ></v-textarea> -->

                  <quill-editor
                    class="mt-4"
                    ref="editor"
                    v-model="popLocal.pop_texto"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                  :disabled="!mixPermissao($options.name, 'edicao')"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar()"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";
import PermissoesMixin from "@/mixins/PermissoesMixin";
// import moment from "moment";
// import tableCompras from "./LojaPopsComprasLista";

const { mapState, mapActions } = createNamespacedHelpers("utilPops");

export default {
  name: "UtilPopsForm",
  mixins: [validationMixin, DatasMixin, PermissoesMixin],
  // components: { tableCompras },
  // validations: {
  //   popLocal: {
  //     nome_pop: { required },
  //     email: { required, email },
  //     pop: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    tituloForm: "",
    popLocal: {},
    agora: undefined,
    comprasIconDisable: false,
  }),
  mounted() {},
  computed: {
    ...mapState(["popSelecionado", "listaUsuarios"]),
    // nomeErrors() {
    //   const errors = [];
    //   if (!this.$v.popLocal.nome_pop.$dirty) return errors;
    //   !this.$v.popLocal.nome_pop.required &&
    //     errors.push("Nome requerido.");
    //   return errors;
    // },
    // emailErrors() {
    //   const errors = [];
    //   if (!this.$v.popLocal.email.$dirty) return errors;
    //   !this.$v.popLocal.email.email && errors.push("E-mail inválido");
    //   !this.$v.popLocal.email.required && errors.push("E-mail requerido");
    //   return errors;
    // },
    // popErrors() {
    //   const errors = [];
    //   if (!this.$v.popLocal.pop.$dirty) return errors;
    //   !this.$v.popLocal.pop.required &&
    //     errors.push("Usuário requerido.");
    //   return errors;
    // },
    // senhaErrors() {
    //   const errors = [];
    //   if (!this.$v.popLocal.senha.$dirty) return errors;
    //   !this.$v.popLocal.senha.required && errors.push("Senha requerida.");
    //   return errors;
    // },
  },

  watch: {
    popSelecionado(popNovo) {
      this.sincronizar(popNovo);
    },
  },

  created() {
    this.agora = new Date().toISOString();
    this.sincronizar(this.popSelecionado);
    if (this.popSelecionado) {
      this.tituloForm = "Editar Pop";
    } else {
      this.tituloForm = "Novo Pop";
      this.comprasIconDisable = true;
    }
    this.metDatasDB();
  },
  methods: {
    ...mapActions(["selecionarPop", "resetarPop", "criarPop", "editarPop"]),

    obterUsuario(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].usuario;
      } else {
        const ret = "";
        return ret;
      }
    },

    selecionarPopCompras() {
      // this.selecionarPop({ pop });
      this.$router.push({ name: "LojaPopsComprasLista" });
    },
    selecionarPopEdicao(pop) {
      this.selecionarPop({ pop });
    },
    metDatasForm() {
      this.popLocal.cria_datahora = this.mixDataFormD(
        this.popLocal.cria_datahora
      );
    },
    metDatasDB() {
      this.popLocal.cria_datahora = this.mixDataDBD(
        this.popLocal.cria_datahora
      );
    },

    async salvarPop() {
      switch (this.popSelecionado) {
        case undefined:
          this.popLocal.cria_usuario = this.$store.state.login.usuario.id;
          this.popLocal.cria_datahora = this.mixDataAgoraBR();
          this.metDatasForm();
          await this.criarPop({
            pop: this.popLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.metDatasDB();
          this.$root.snackbar.show({
            type: "success",
            message: "POP Criado!",
          });

          break;
        default:
          this.metDatasForm();
          await this.editarPop({
            pop: this.popLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.metDatasDB();
          this.$root.snackbar.show({
            type: "success",
            message: "POP Salvo!",
          });
      }
      //this.resetar();
    },

    sincronizar(novoPop) {
      this.popLocal = Object.assign(
        {},
        novoPop || {
          id: null,
          cria_datahora: null,
          mod_datahora: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarPop();
      // this.$router.back();
    },
    metClear() {},
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
